import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Select, Tooltip } from 'antd';
import AntColorPicker from 'uiKits/ColorPicker';
const { Option } = Select;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #000;
  &.active {
    padding: 5px;
    background: lightgray;
  }
`;

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
  margin-bottom: 14px;
  justify-content: space-between;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    line-height: 30px;
  }
  .ant-select-selection-placeholder {
    font-family: Muli, Helvetica, Arial, sans-serif;
  }
`;

export const StyledOption = styled(Option)``;

export const StyledColorPicker = styled(AntColorPicker)`
  width: 50px !important;
  border: unset !important;
  background-color: unset !important;
`;

export const StyledTooltip = styled(Tooltip)``;
