import styled from 'styled-components';
import { Row, Tooltip, Select } from 'antd';

export const StyledTitle = styled.p`
  font-family: 'Muli', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  margin: 0;
  padding: 16px 28px;
  background-color: #000;
`;

export const ToolsContainer = styled.div`
  padding: 16px;
  width: 60%;
  margin: 0 auto;
  height: calc(100vh - 160px);
  overflow-y: scroll;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
  margin-bottom: ${(props) => (props.nomargin === 'true' ? '0' : '14px')};
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
`;

export const StyledGroupTitle = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledSelect = styled(Select)`
  min-width: 80px;
  .ant-select-selector {
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    line-height: 30px;
  }
  .ant-select-selection-placeholder {
    font-family: Muli, Helvetica, Arial, sans-serif;
  }
`;

export const EditorContainer = styled.div`
  margin-bottom: 20px;
`;
