import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedPage,
  addPageAfter,
  duplicatePage,
  deletePage,
  unsetSelection,
} from 'slices/editorSlice';
import globals from 'websiteGlobals';
import Content from '../../Playground/Content';
import ImageWithTooltip from 'components/Common/Tooltip';
import { faCopy, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { StyledPlaygroundPage, StyledPageContent, StyledPageNumber, ActionsIcons } from './styles';

const PreviewPage = ({ pageData, pageIdx }) => {
  const dispatch = useDispatch();
  const selectedPageId = useSelector((state) => state.editor.selectedPageId);
  const isPageSelected = pageData.unique_page_id === selectedPageId;

  const pageWidth =
    globals.constants.pageSize[pageData.settings?.orientation === 'portrait' ? 1 : 0];

  const pageHeight =
    globals.constants.pageSize[pageData.settings?.orientation === 'portrait' ? 0 : 1];

  let pageStyles = {
    backgroundColor: pageData.settings?.background,
    width: pageWidth + 'px',
    height: pageHeight + 'px',
    overflow: 'hidden',
    border: '2px solid transparent',
    transform: 'scale(0.26)',
    transformOrigin: '50px 50px',
  };

  const handlePageClick = () => {
    if (isPageSelected) {
      dispatch(unsetSelection());
    } else {
      dispatch(setSelectedPage({ pageId: pageData.unique_page_id, pageIdx: pageIdx }));
    }
  };

  const renderContent = () => {
    if (!pageData || !pageData.content) return null;

    return pageData.content.map((content, idx) => (
      <Content
        key={content.unique_content_id}
        contentData={content}
        locked={pageData.settings.locked || false}
        pageId={pageData.unique_page_id}
        pageIdx={pageIdx}
        contentId={content.unique_content_id}
        pageType={pageData.type}
        pageWidth={pageWidth}
        pageHeight={pageHeight}
      />
    ));
  };

  if (isPageSelected) {
    pageStyles.border = '2px solid #02b183';
  }

  return (
    <>
      <StyledPlaygroundPage
        id={`linesheet-editor-playground-page-${pageData.unique_page_id}`}
        className="linesheet-editor-playground-page"
        style={pageStyles}
        onClick={() => handlePageClick()}
      >
        <StyledPageContent>{renderContent()}</StyledPageContent>
      </StyledPlaygroundPage>
      {isPageSelected && (
        <ActionsIcons>
          <ImageWithTooltip
            icon={faPlus}
            title="Add Page"
            placement="right"
            onClick={() => dispatch(addPageAfter())}
          />
          <ImageWithTooltip
            icon={faCopy}
            title="Duplicate Page"
            placement="right"
            onClick={() => dispatch(duplicatePage())}
          />
          <ImageWithTooltip
            icon={faTrash}
            title="Delete Page"
            placement="right"
            onClick={() => dispatch(deletePage())}
          />
        </ActionsIcons>
      )}
      <StyledPageNumber>{(pageIdx | 0) + 1}</StyledPageNumber>
    </>
  );
};

export default PreviewPage;
