import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Common/Loader';
import GlobalEditorTools from '../RightPanel/GlobalEditorTools';
import { setIsEditorLoading, applyGlobalSettings } from 'slices/editorSlice';
import { setAlert } from 'slices/alertMessageSlice';
import {
  MainContainer,
  ProductListingContainer,
  StyledRow,
  StyledCol,
  ProductBox,
  ProductImageContainer,
  ProductImage,
  EllipsisTooltip,
  ButtonsWrapper,
  StyledTitle,
  BackButton,
  SaveButton,
} from './styles';
import { message } from 'antd';

const GlobalSettingsPanel = () => {
  const dispatch = useDispatch();
  const { pages, products: linesheetProducts } = useSelector((state) => state.editor.linesheetData);
  const isEditorLoading = useSelector((state) => state.editor.isEditorLoading);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showSettingsStep, setShowSettingsStep] = useState(false);
  const settings = {
    value: '',
    font: null,
    size: null,
    color: '#000',
    bold: true,
    italic: false,
    underline: false,
    align: 'center',
    show: null,
  };
  const [stylingState, setStylingState] = useState({
    titleStyling: settings,
    skuStyling: settings,
    descriptionStyling: settings,
    wholesalePriceStyling: settings,
    retailPriceStyling: settings,
  });

  useEffect(() => {
    dispatch(setIsEditorLoading({ isLoading: true }));
    const productsData = {};

    pages.forEach((page, pageIndex) => {
      page.content
        .filter((content) => content.product_id !== '' && content.product_id !== null)
        .forEach((content, contentIndex) => {
          if (!productsData[content.product_id]) {
            const product = linesheetProducts.find((product) => product.id === content.product_id);
            productsData[content.product_id] = {
              id: content.product_id,
              pageIndex: pageIndex,
              contentIndex: contentIndex,
              product_image: product.product_image,
              name: product.name,
            };
          }

          if (content.text_content !== null) {
            productsData[content.product_id].contentIndex = contentIndex;
          }
        });
    });
    setProducts(Object.values(productsData));
    dispatch(setIsEditorLoading({ isLoading: false }));
    // eslint-disable-next-line
  }, []);

  const isProductSelected = (productId) => {
    return selectedProducts.some((selectedProduct) => selectedProduct.id === productId);
  };

  const handleProductSelections = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      return isProductSelected(product.id)
        ? prevSelectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id)
        : [...prevSelectedProducts, product];
    });
  };

  const handleSaveButton = () => {
    dispatch(applyGlobalSettings({ selectedProducts, stylingState }));
    setSelectedProducts([]);
    setIsSelectAll(false);
    dispatch(
      setAlert({
        message: 'Global settings have been implemented for the selected products.',
        type: 'success',
      }),
    );
    setShowSettingsStep(false);
    setStylingState({
      titleStyling: settings,
      skuStyling: settings,
      descriptionStyling: settings,
      wholesalePriceStyling: settings,
      retailPriceStyling: settings,
    });
  };

  const handleSelectAll = (val) => {
    setIsSelectAll(val);
    setSelectedProducts(val ? products : []);
  };

  const showNextStep = () => {
    if (selectedProducts.length === 0) {
      message.error('Please select some products first');
      return;
    }

    setShowSettingsStep(true);
  };

  return (
    <MainContainer>
      {showSettingsStep ? (
        <>
          <ButtonsWrapper>
            <BackButton onClick={() => setShowSettingsStep(false)}>Back</BackButton>
            <StyledTitle>Set the Settings</StyledTitle>
            <SaveButton onClick={() => handleSaveButton()}>Save</SaveButton>
          </ButtonsWrapper>
          <GlobalEditorTools stylingState={stylingState} setStylingState={setStylingState} />
        </>
      ) : (
        <>
          <ButtonsWrapper>
            <SaveButton
              disabled={products.length === 0}
              onClick={() => handleSelectAll(!isSelectAll)}
            >
              {isSelectAll ? 'Unselect' : 'Select All'}
            </SaveButton>
            <StyledTitle>Select Products</StyledTitle>
            <SaveButton disabled={products.length === 0} onClick={() => showNextStep()}>
              Next
            </SaveButton>
          </ButtonsWrapper>
          <ProductListingContainer>
            {products.length > 0 ? (
              <StyledRow>
                {products.map((product, index) => (
                  <StyledCol key={index} onClick={() => handleProductSelections(product)}>
                    <ProductBox
                      className={isSelectAll || isProductSelected(product.id) ? 'checked' : ''}
                    >
                      <ProductImageContainer>
                        <ProductImage
                          src={product.product_image}
                          alt={product.name}
                          loading="lazy"
                        />
                      </ProductImageContainer>
                      <EllipsisTooltip>{product.name}</EllipsisTooltip>
                    </ProductBox>
                  </StyledCol>
                ))}
              </StyledRow>
            ) : (
              <p>No Products available for global settings</p>
            )}
          </ProductListingContainer>
        </>
      )}
      <Loader isLoading={isEditorLoading} />
    </MainContainer>
  );
};

export default GlobalSettingsPanel;
