import { useDispatch } from 'react-redux';
import { updateFontSettings, updateLinesheetFontSettings } from 'slices/editorSlice';

import { faAlignLeft, faAlignCenter, faAlignRight } from '@fortawesome/free-solid-svg-icons';

const EditorTextDirectionToolsComponent = () => {
  const dispatch = useDispatch();

  const textDecorationClickHandler = (val, isGlobal) => {
    const payload = { key: 'align', value: val };
    const action = isGlobal ? updateLinesheetFontSettings : updateFontSettings;
    dispatch(action(payload));
  };

  const TextDirectionTools = [
    {
      name: 'left',
      tooltip: 'Left Justify',
      icon: faAlignLeft,
      disabled: false,
      action: textDecorationClickHandler,
    },
    {
      name: 'center',
      tooltip: 'Middle Justify',
      icon: faAlignCenter,
      disabled: false,
      action: textDecorationClickHandler,
    },
    {
      name: 'right',
      tooltip: 'Right Justify',
      icon: faAlignRight,
      disabled: false,
      action: textDecorationClickHandler,
    },
  ];

  return TextDirectionTools;
};

export default EditorTextDirectionToolsComponent;
