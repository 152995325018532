import styled from 'styled-components';
import AntButton from 'uiKits/Button';
import { Button } from 'antd';

export const MainContainer = styled.div`
  padding: 60px 25px 25px 25px;
`;

export const ProductListingContainer = styled.div`
  height: calc(100vh - 130px);
  overflow-y: scroll;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledCol = styled.div`
  height: auto;
  position: relative;
  width: 200px;
`;

export const ProductBox = styled.div`
  height: 200px;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  background-color: #fff;
  border: 1px solid transparent;
  cursor: pointer;
  &.checked {
    border: 1px solid #02b183;
    background-color: #c0ece0;
  }
`;

export const ProductImageContainer = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 64px;
  justify-content: center;
`;

export const ProductImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const EllipsisTooltip = styled.span`
  max-width: 95%;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-size: 12px;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const BackButton = styled(Button)`
  border-radius: 10000px;
  margin-right: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const SaveButton = styled(AntButton)`
  border-radius: 10000px;
  font-weight: 600;
  border-width: 2px;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  line-height: 0.5;
`;

export const StyledTitle = styled.h5`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
  margin: 0;
`;
