import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertContainer, StyledAlert } from './styles';
import { clearAlert } from 'slices/alertMessageSlice';

const AlertMessages = () => {
  const dispatch = useDispatch();
  const { message, type, pages } = useSelector((state) => state.alertMessage);

  const handleAlertClose = (e) => {
    dispatch(clearAlert());
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const errorMessage = (
    <span>
      Please update the contents on these pages:&nbsp;
      {pages.map((page, index) => (
        <span key={page}>
          <a onClick={() => scrollToSection(`linesheet-editor-playground-page-${page - 1}`)}>
            {page}
          </a>
          {index < pages.length - 1 && ', '}
        </span>
      ))}
    </span>
  );

  return (
    message && (
      <AlertContainer>
        <StyledAlert
          message={pages.length > 0 ? errorMessage : message}
          type={type}
          showIcon
          closable
          onClose={handleAlertClose}
        />
      </AlertContainer>
    )
  );
};

export default AlertMessages;
