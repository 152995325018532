import React, { useState } from 'react';
import globals from 'websiteGlobals';
import { DownOutlined } from '@ant-design/icons';
import EditorTextDecorationToolsComponent from '../EditorTextDecorationTools';
import EditorTextDirectionToolsComponent from '../EditorTextDirectionTools';
import {
  StyledFontAwesomeIcon,
  StyledRow,
  StyledSelect,
  StyledOption,
  StyledColorPicker,
  StyledTooltip,
} from './styles';

const GlobalFontSettings = ({ fontSettings, type = '', handleSettingsChange }) => {
  const [open, setOpen] = useState(false);
  const fonts = globals.constants.fonts;
  const fontSizes = globals.constants.fontSizes;
  const TextDecorationTools = EditorTextDecorationToolsComponent();
  const TextAlignmentTools = EditorTextDirectionToolsComponent();

  const handleFontChange = (value) => handleSettingsChange(type, 'font', value);
  const handleFontSizeChange = (value) => handleSettingsChange(type, 'size', value);
  const handleColorChange = (value) => handleSettingsChange(type, 'color', `#${value.toHex()}`);

  return (
    <>
      <StyledRow>
        <StyledSelect
          onChange={handleFontChange}
          placeholder="Select Font Family"
          value={fontSettings.font}
          popupClassName="editor-font-select"
        >
          {fonts.map((font) => (
            <StyledOption key={font.key} value={font.name} style={{ fontFamily: font.name }}>
              {font.name}
            </StyledOption>
          ))}
        </StyledSelect>
        <StyledSelect
          onChange={handleFontSizeChange}
          placeholder={'Select Font Size'}
          value={fontSettings.size}
          popupClassName="editor-font-select"
        >
          {fontSizes.map((size) => (
            <StyledOption key={size} value={size}>
              {size}
            </StyledOption>
          ))}
        </StyledSelect>
        {TextDecorationTools.map((tool, index) => (
          <StyledTooltip title={tool.tooltip} placement="left">
            <StyledFontAwesomeIcon
              className={fontSettings[tool.name] === true ? 'active' : ''}
              key={index}
              icon={tool.icon}
              onClick={() => handleSettingsChange(type, tool.name, !fontSettings[tool.name])}
            />
          </StyledTooltip>
        ))}
        {TextAlignmentTools.map((tool, index) => (
          <StyledTooltip title={tool.tooltip} placement="left">
            <StyledFontAwesomeIcon
              className={fontSettings.align === tool.name ? 'active' : ''}
              key={index}
              icon={tool.icon}
              onClick={() => handleSettingsChange(type, 'align', tool.name)}
            />
          </StyledTooltip>
        ))}
        <StyledColorPicker
          onChange={handleColorChange}
          defaultValue="#000"
          value={fontSettings.color}
          open={open}
          onOpenChange={setOpen}
          showText={() => (
            <DownOutlined
              rotate={open ? 180 : 0}
              style={{
                color: `${fontSettings.color}`,
                filter: 'invert(1)',
              }}
            />
          )}
        />
      </StyledRow>
    </>
  );
};

export default GlobalFontSettings;
