import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useUpdateLinesheetMutation } from 'api/linesheetApi';
import LinesheetEditorHeader from './LinesheetEditorHeader';
import LeftPanel from './LeftPanel';
import EditorPanel from './EditorPanel';
import RightPanel from './RightPanel';
import Loader from 'components/Common/Loader';
import { resetEditorStates, setShowDownloadConfirmationModal } from 'slices/editorSlice';
import { inActiveSubscription } from 'slices/authSlice';
import LinesheetPreviewPage from './LinesheetPreviewPage';
import AlertMessages from '../Common/AlertMessages';
import ConfirmTemplateModal from './ConfirmTemplateModal';
import DownloadConfirmationModal from './DownloadConfirmationModal';
import { message } from 'antd';
import BackModal from './BackModal';
import NewNameModal from './NewNameModal';
import SubscriptionModal from 'components/MyProfile/SubscriptionPlanModal';
import GlobalSettingsPanel from './GlobalSettingsPanel';
import { setAlert } from 'slices/alertMessageSlice';
import {
  MainContainer,
  LinesheetEditorBody,
  StyledLeftPanel,
  StyledEditorPanel,
  StyledRightPanel,
} from './styles';
const LinesheetEditor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isBack, setIsBack] = useState(false);
  const [showNewNameModal, setShowNewNameModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const { id, name, settings, pages, pdf_url } = useSelector(
    (state) => state.editor.linesheetData,
    shallowEqual,
  );
  const inPreview = useSelector((state) => state.editor.inPreview, shallowEqual);
  const isEditorLoading = useSelector((state) => state.editor.isEditorLoading, shallowEqual);
  const { status, planType } = useSelector((state) => state.auth.user.subscription);
  const inGlobalSettingsMode = useSelector((state) => state.editor.inGlobalSettingsMode);
  const [updateLinesheet, { isLoading: updateLinesheetLoading }] = useUpdateLinesheetMutation();

  const handleDntSave = () => {
    dispatch(resetEditorStates());
    navigate('/linesheets');
  };

  const saveAndDownloadApi = async (exportPDF, saveFromBackModal = false) => {
    const payload = { settings, pages, exportPDF };

    try {
      const result = await updateLinesheet({ linesheetId: id, payload }).unwrap();
      message.success(result?.message);
      if (planType === 'oneTime') dispatch(inActiveSubscription());
      if (saveFromBackModal) navigate('/linesheets');
    } catch (error) {
      message.error('Failed to save linesheet');
    }
  };

  const handleSave = (exportPDF, saveFromBackModal = false) => {
    setIsBack(false);
    if (pages.length <= 0) return message.error('Please add some pages');

    const notFormattedPageIndexes = pages
      .map((page, index) => (page.settings.isNotFormatted ? index + 1 : -1))
      .filter((index) => index !== -1);
    if (notFormattedPageIndexes.length > 0) {
      dispatch(setAlert({ message: 'Page Error', type: 'error', pages: notFormattedPageIndexes }));
      return;
    }

    if ((status === 'inactive' || planType === null) && exportPDF) {
      if (pdf_url !== null) {
        dispatch(setShowDownloadConfirmationModal());
      } else {
        setShowSubscriptionModal(true);
      }
      return;
    }

    saveAndDownloadApi(exportPDF, saveFromBackModal);
  };

  return (
    <MainContainer>
      <LinesheetEditorHeader
        linesheetName={name}
        setIsBack={setIsBack}
        handleSave={() => handleSave(false)}
        handleSaveAsNew={() => setShowNewNameModal(true)}
        handleGeneratePDF={() => handleSave(true)}
        isBack={isBack}
        inPreview={inPreview}
        inGlobalSettingsMode={inGlobalSettingsMode}
      />
      {inGlobalSettingsMode && <GlobalSettingsPanel />}
      {inPreview && <LinesheetPreviewPage />}
      {!inPreview && !inGlobalSettingsMode && (
        <LinesheetEditorBody>
          {!inGlobalSettingsMode && (
            <StyledLeftPanel>
              <LeftPanel />
            </StyledLeftPanel>
          )}
          <StyledEditorPanel>
            <EditorPanel />
          </StyledEditorPanel>
          <StyledRightPanel>
            <RightPanel />
          </StyledRightPanel>
        </LinesheetEditorBody>
      )}
      <BackModal
        isBack={isBack}
        setIsBack={setIsBack}
        handleDntSave={handleDntSave}
        handleSave={() => handleSave(false, true)}
      />
      <NewNameModal showNewNameModal={showNewNameModal} setShowNewNameModal={setShowNewNameModal} />
      <AlertMessages />
      <ConfirmTemplateModal />
      <DownloadConfirmationModal
        setShowSubscriptionModal={setShowSubscriptionModal}
        saveAndDownloadApi={() => saveAndDownloadApi(true)}
      />
      <SubscriptionModal
        isOpen={showSubscriptionModal}
        setShowSubscriptionModal={setShowSubscriptionModal}
      />
      <Loader isLoading={updateLinesheetLoading || isEditorLoading} />
    </MainContainer>
  );
};

export default React.memo(LinesheetEditor);
