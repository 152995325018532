import React from 'react';
import Text from '../Text';
import { ProductContainer, StyledProductInner, StyledImageContainer, StyledImage } from './styles';

const Product = ({ contentData }) => {
  const imageStyle = {
    transform: contentData.image_content?.settings?.transform,
    WebkitTransform: contentData.image_content?.settings?.WebkitTransform,
    MozTransform: contentData.image_content?.settings?.MozTransform,
    height: `${contentData.image_content?.settings?.height}px`,
  };

  const imgStyles = {
    objectFit: contentData.image_content?.settings?.size,
  };

  const prodInnerStyle = {
    gap: `${contentData.settings?.spaceBetween}px`,
    display: 'flex',
    flexDirection: 'column',
  };

  const renderImage = () => {
    if (!contentData?.image_content) return null;

    const productImages = contentData.image_content.product_images;
    const imageIndex = contentData.image_content.image_index;
    const productImageUrl =
      imageIndex === 1 ? contentData.image_content.url : productImages[imageIndex - 1];

    return (
      <StyledImageContainer style={imageStyle}>
        <StyledImage
          src={productImageUrl}
          width={contentData.settings?.width}
          height={contentData.image_content.settings?.height}
          alt="image"
          style={imgStyles}
        />
      </StyledImageContainer>
    );
  };

  const renderContent = () => {
    if (!contentData?.text_content) return null;

    const { title, sku, description, wholesalePrice, retailPrice } = contentData.text_content;
    return (
      <>
        {title?.settings?.show && <Text data={title} type="title" />}
        {sku?.settings?.show && <Text data={sku} type="sku" />}
        {description?.settings?.show && <Text data={description} type="description" />}
        {wholesalePrice?.settings?.show && <Text data={wholesalePrice} type="wholesalePrice" />}
        {retailPrice?.settings?.show && <Text data={retailPrice} type="retailPrice" />}
      </>
    );
  };

  return (
    <ProductContainer>
      <StyledProductInner style={prodInnerStyle}>
        {contentData.settings?.productArrangment === 'top' ? (
          <>
            {renderImage()}
            {renderContent()}
          </>
        ) : (
          <>
            {renderContent()}
            {renderImage()}
          </>
        )}
      </StyledProductInner>
    </ProductContainer>
  );
};

export default Product;
