import React from 'react';
import GlobalFontSettings from '../GlobalFontSettings';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  ToolsContainer,
  StyledRow,
  StyledGroupTitle,
  StyledTooltip,
  StyledSelect,
  EditorContainer,
} from './styles';

const GlobalEditorTools = ({ stylingState, setStylingState }) => {
  const handleSettingsChange = (category, key, value) => {
    const type = `${category}Styling`;

    setStylingState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [key]: value,
      },
    }));
  };

  const handleEditorChange = (editor, category) => {
    const data = editor.getData();
    const type = `${category}Styling`;
    setStylingState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        value: data,
      },
    }));
  };

  const FontSettingsRow = ({ label, styling, type }) => (
    <>
      <StyledRow justify="space-between" wrap={false}>
        <StyledGroupTitle>{label}</StyledGroupTitle>
        <StyledTooltip title={`Show Products ${label}`} placement="left">
          <StyledSelect
            onChange={(value) => handleSettingsChange(type, 'show', value)}
            placeholder="Action"
            value={styling.show}
            popupClassName="editor-font-select"
            options={[
              { value: 'show', label: <span>Show</span> },
              { value: 'hide', label: <span>Hide</span> },
              { value: 'none', label: <span>Keep Unchanged</span> },
            ]}
          />
        </StyledTooltip>
      </StyledRow>
      {/* {styling.show && ( */}
      <>
        <GlobalFontSettings
          fontSettings={{
            font: styling.font,
            size: styling.size,
            color: styling.color,
            bold: styling.bold,
            italic: styling.italic,
            underline: styling.underline,
            align: styling.align,
          }}
          type={type}
          handleSettingsChange={handleSettingsChange}
        />
        <EditorContainer>
          <StyledGroupTitle>Set Products {label}</StyledGroupTitle>
          <CKEditor
            editor={ClassicEditor}
            data={styling.value}
            onBlur={(event, editor) => handleEditorChange(editor, type)}
            config={{
              toolbar: ['numberedList', 'bulletedList'],
            }}
          />
        </EditorContainer>
      </>
      {/* )} */}
    </>
  );

  return (
    <>
      <ToolsContainer>
        <FontSettingsRow label="Title" styling={stylingState.titleStyling} type="title" />
        <FontSettingsRow label="SKU" styling={stylingState.skuStyling} type="sku" />
        <FontSettingsRow
          label="Description"
          styling={stylingState.descriptionStyling}
          type="description"
        />
        <FontSettingsRow
          label="Wholesale Price"
          styling={stylingState.wholesalePriceStyling}
          type="wholesalePrice"
        />
        <FontSettingsRow
          label="Retail Price"
          styling={stylingState.retailPriceStyling}
          type="retailPrice"
        />
      </ToolsContainer>
    </>
  );
};

export default GlobalEditorTools;
